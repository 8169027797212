import React from "react"

import Button from "../../shared/Button"
import "./Hero.scss"
export default function Hero({ btnText, img, text, header, id, social }) {
  return (
    <div className="hero-bg" id={id}>
      <div className="container position-relative">
        <div className="row pb-4">
          <div className="col-lg-7 col-sm-12 col-xs-12  hero-text-column-thanku">
            <p className="header">{header}</p>
            <div className="hero-text-thanku -mt-3">{text}</div>
            <br></br>
            <div className="mb-4 mb-sm-4" mb={{ sm: 5, xs: 4 }}>
              <Button text={btnText} variant="fill" href="/portfolio" />
            </div>
          </div>
          <div className="col-lg-5  col-sm-12  col-xs-12  mt-sm-0 mt-5">
            <div className="hero-img-thanku position-relative">
              <img
                loading="lazy"
                src={img}
                alt="thankyou-hero"
                className="img-fluid display-tablet-none"
              />
            </div>
          </div>
        </div>
        {!!social ? (
          <div className="social-icons-box">
            <div className="social-heading">Follow Us</div>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/codefulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="linkedin.svg"
                  alt="linkedin"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://www.facebook.com/codeFulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="instagram.svg"
                  alt="instagram"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://www.upwork.com/ag/codefulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="facebook.svg"
                  alt="facebook"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}
